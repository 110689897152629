<template>
    <div class="practical-train">
        <table-page-template
            v-bind="templateConfig"
            :pagingConfig.sync="getPaperParams"
            :totalCount="totalCount"
            @page-change="getPaperData"
        >
            <template #tableOperate="{ item }">
                <el-link type="primary" :underline="false" @click="editPaper(item)">编辑</el-link>
                <el-link type="danger" :underline="false" @click="deletePaper(item)">删除</el-link>
            </template>
        </table-page-template>
        <my-dialog class="delete-paper" :is-show.sync="deleteDialog" :title="`删除试卷`" :confirmHandler="confirmDelete">
            <div class="content">是否删除该试卷, 删除后将无法恢复</div>
        </my-dialog>
    </div>
</template>

<script>
import TablePageTemplate from "components/common/TablePageTemplate";
import MyDialog from "components/common/MyDialog";
import { getPracticalTrainData, deletePracticalTrain, showScriptListData } from "@/utils/apis";
export default {
    name: "PracticalTrainManage",
    data() {
        return {
            tableData: [],
            getPaperParams: {
                paging: 1,
                page: 1,
                pageSize: 10,
                id: "",
            },
            totalCount: 1,
            deleteId: "",
            deleteDialog: false,
        };
    },
    components: {
        TablePageTemplate,
        MyDialog,
    },
    computed: {
        templateConfig() {
            return {
                tableData: this.tableData,
                rightConfig: {
                    text: `添加试卷`,
                    handler: this.createPaper,
                },
                tableConfig: [
                    { label: `试卷名称`, prop: "paper_name", width: 500 },
                    { label: "实训模板", prop: "training_template", align: "center" },
                    { label: "操作", width: 300, align: "center" },
                ],
            };
        },
    },
    methods: {
        init() {
            this.getPaperData();
        },
        async getPaperData() {
            const res = await getPracticalTrainData(this.getPaperParams);
            this.tableData = res.data.list;
            this.totalCount = res.data.total;
        },
        createPaper() {
            this.$router.push("/liveStreaming/practicalTrainOperate");
        },
        editPaper(item) {
            this.$router.push({
                path: "/liveStreaming/practicalTrainOperate",
                query: {
                    id: item.id,
                },
            });
        },
        deletePaper(item) {
            this.deleteId = item.id;
            this.deleteDialog = true;
        },
        async confirmDelete() {
            const res = await deletePracticalTrain({ id: this.deleteId });
            this.$message.success(res.msg);
            this.deleteDialog = false;
            this.getPaperData();
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style scoped lang="scss">
.practical-train {
    box-sizing: border-box;
    height: 100%;
    padding: 20px 20px 0;
}
</style>
